<template>
  <div class="">
    <el-tabs v-model="registername" @tab-click="registerhandleClick">
      <el-tab-pane label="手机注册" name="shouji">
        <el-form
          ref="loginForm"
          :model="sjregisterform"
          :rules="sjrules"
          class="from-box1"
        >
          <el-form-item prop="phone"
            ><el-input
              type="text"
              maxlength="11"
              placeholder="请输入手机号码"
              v-model="sjregisterform.phone"
          /></el-form-item>
          <el-form-item prop="code">
            <div class="verify-wrapper" style="display: flex">
              <el-input
                type="text"
                maxlength="6"
                placeholder="请输入短信验证码"
                v-model="sjregisterform.code"
              ></el-input>
              <el-button
                class="btn-orange"
                :disabled="sjdisabled"
                @click="getCodephone"
                >{{ sjvaliBtn }}</el-button
              >
            </div>
          </el-form-item>
          <el-form-item prop="phone"
            ><el-input
              type="text"
              maxlength="20"
              placeholder="请设置6至20位登录密码"
              v-model="sjregisterform.password1"
          /></el-form-item>
          <el-form-item prop="phone"
            ><el-input
              type="text"
              maxlength="20"
              placeholder="请确认登录密码"
              v-model="sjregisterform.password2"
          /></el-form-item>
          <el-form-item>
            <!-- <el-checkbox v-model="sjregisterform.xieyichecked">
							已阅读同意
							<span style="color: #409EFF;">《Freeman用户服务协议》</span>
						</el-checkbox> -->

            <div
              class="agreement font14"
              style="color: #00bcff; margin-top: 10px"
            >
              <el-checkbox v-model="sjregisterform.xieyichecked"></el-checkbox>
              <span>
                已阅读并同意Freemen
                <a
                  href="https://freemen.work/UserAgreement.html"
                  target="_blank"
                  style="text-decoration: none; color: #00bcff"
                  >《用户协议》</a
                >和<a
                  href="https://freemen.work/PrivacyPolicy.html"
                  target="_blank"
                  style="text-decoration: none; color: #00bcff"
                  >《隐私政策》</a
                ></span
              >
            </div>
          </el-form-item>
          <el-form-item
            ><button class="d-btn-orange" @click="zhuce()">
              立即注册
            </button></el-form-item
          >
          <div class="l-tishi font14">
            已有账号？
            <span @click="tologin">快速登录>></span>
          </div>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="邮箱注册" name="youxiang">
        <el-form
          ref="loginForm"
          :model="emailregisterform"
          :rules="emailrules"
          class="from-box1"
        >
          <el-form-item prop="phone"
            ><el-input
              type="text"
              placeholder="请输入邮箱"
              v-model="emailregisterform.email"
          /></el-form-item>
          <el-form-item prop="code">
            <div class="verify-wrapper" style="display: flex">
              <el-input
                type="text"
                maxlength="6"
                placeholder="请输入短信验证码"
                v-model="emailregisterform.code"
              ></el-input>
              <el-button
                class="btn-orange"
                :disabled="emaildisabled"
                @click="getCodeemail"
                >{{ emailvaliBtn }}</el-button
              >
            </div>
          </el-form-item>
          <el-form-item prop="phone"
            ><el-input
              type="text"
              maxlength="20"
              placeholder="请设置6至20位登录密码"
              v-model="emailregisterform.password1"
          /></el-form-item>
          <el-form-item prop="phone"
            ><el-input
              type="text"
              maxlength="20"
              placeholder="请确认登录密码"
              v-model="emailregisterform.password2"
          /></el-form-item>
          <el-form-item>
            <!-- <el-checkbox v-model="emailregisterform.xieyichecked">
							已阅读同意
							<span style="color: #409EFF;">《Freeman用户服务协议》</span>
						</el-checkbox> -->

            <div
              class="agreement font14"
              style="color: #00bcff; margin-top: 10px"
            >
              <el-checkbox
                v-model="emailregisterform.xieyichecked"
              ></el-checkbox>
              <span>
                已阅读并同意Freemen
                <a
                  href="https://freemen.work/UserAgreement.html"
                  target="_blank"
                  style="text-decoration: none; color: #00bcff"
                  >《用户协议》</a
                >和<a
                  href="https://freemen.work/PrivacyPolicy.html"
                  target="_blank"
                  style="text-decoration: none; color: #00bcff"
                  >《隐私政策》</a
                ></span
              >
            </div>
          </el-form-item>
          <el-form-item
            ><button class="d-btn-orange">立即注册</button></el-form-item
          >
          <div class="l-tishi font14">
            已有账号？
            <span @click="tologin">快速登录>></span>
          </div>
        </el-form>
      </el-tab-pane>
    </el-tabs>
    <!-- 注册成功弹窗 -->
    <el-dialog
      class=""
      :visible.sync="centerDialogVisible"
      width="550px"
      center
      :show-close="false"
      :modal-append-to-body="false"
    >
      <div class="registersuccess">
        <div class="gou_img">
          <img src="../../assets/images/common/gou-chenggong.png" />
        </div>
        <div class="congratulations font18">恭喜你，注册成功</div>

        <div class="finish-text font14">
          完成实名认证，
          <span>即可发布招聘职位</span>
        </div>
        <div class="succes-btn">
          <el-button @click="centerDialogVisible = false"
            >点击实名认证</el-button
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    var checkPhone = (rule, value, callback) => {
      const reg =
        /^0?(13[0-9]|15[012356789]|18[0-9]|14[578]|16[6]|17[035768]|19[19])[0-9]{8}$/;
      if (reg.test(value)) {
        return callback();
      }
      callback("请输入合法的手机号");
    };
    return {
      registername: "shouji",
      // 手机
      sjregisterform: {
        phone: "",
        code: "",
        password1: "",
        password2: "",
        xieyichecked: false,
      },
      sjrules: {
        phone: [{ validator: checkPhone, required: true, trigger: "blur" }],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
      sjvaliBtn: "获取验证码",
      sjdisabled: false,
      // 邮箱
      emailregisterform: {
        email: "",
        minum: "",
        password1: "",
        password2: "",
        xieyichecked: false,
      },
      emailrules: {
        phone: [{ validator: checkPhone, required: true, trigger: "blur" }],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
      emailvaliBtn: "获取验证码",
      emaildisabled: false,
      centerDialogVisible: false, //注册成功的弹窗
    };
  },
  methods: {
    zhuce() {
      this.centerDialogVisible = !this.centerDialogVisible;
    },
    tologin() {
      this.$emit("fastlogin", "2");
    },
    registerhandleClick(tab, event) {
      // console.log(tab, event);
    },
    //手机获取验证码
    getCodephone() {
      this.$refs["loginForm"].validateField("phone", (err) => {
        if (err) {
          console.log("未通过");
          return;
        } else {
          console.log("已通过");
          this.tackBtnPhone(); //验证码倒数60秒
          let fd = new FormData(); //POST 请求需要 转化为Form
          fd.append("PhoneNumber", this.form.phone);
          fd.append("NeedCheck", 2);
          // this.$axios({
          // 	method: 'POST',
          // 	data: fd,
          // 	url: '/api/sgsaccount/vcodeget'
          // }).then(res => {
          // 	console.log(res);
          // });
        }
      });
    },
    tackBtnPhone() {
      //验证码倒数60秒
      let time = 60;
      let timer = setInterval(() => {
        if (time == 0) {
          clearInterval(timer);
          this.sjvaliBtn = "获取验证码";
          this.sjdisabled = false;
        } else {
          this.sjdisabled = true;
          this.sjvaliBtn = time + "秒后重发";
          time--;
        }
      }, 1000);
    },
    //邮箱获取验证码
    getCodeemail() {
      this.$refs["loginForm"].validateField("phone", (err) => {
        if (err) {
          console.log("未通过");
          return;
        } else {
          console.log("已通过");
          this.tackBtnEmail(); //验证码倒数60秒
          let fd = new FormData(); //POST 请求需要 转化为Form
          fd.append("PhoneNumber", this.form.phone);
          fd.append("NeedCheck", 2);
          // this.$axios({
          // 	method: 'POST',
          // 	data: fd,
          // 	url: '/api/sgsaccount/vcodeget'
          // }).then(res => {
          // 	console.log(res);
          // });
        }
      });
    },
    tackBtnEmail() {
      //验证码倒数60秒
      let time = 60;
      let timer = setInterval(() => {
        if (time == 0) {
          clearInterval(timer);
          this.emailvaliBtn = "获取验证码";
          this.emaildisabled = false;
        } else {
          this.emaildisabled = true;
          this.emailvaliBtn = time + "秒后重发";
          time--;
        }
      }, 1000);
    },
    onSubmit(formName) {
      //点击登录 验证手机& 验证码是否符合条件
      this.$refs[formName].validate((valid) => {
        // 为表单绑定验证功能
        if (valid) {
          console.log("正确登陆");
          return;
        } else {
          this.dialogVisible = true;
          return false;
        }
      });
    },
  },
};
</script>
<style>
.el-tabs__nav-wrap::after {
  background-color: #fff !important;
}
.el-tabs__nav-scroll {
  display: flex;
  align-items: center;
  justify-content: center;
}
.el-tabs__item.is-active {
  color: #303133;
}
</style>
<style lang="less" scoped>
.from-box1 {
  /deep/ .el-input__inner {
    border-radius: 0px;
  }
  /deep/ .el-button {
    border-radius: 0px;
  }

  .btn-orange {
    margin-left: 10px;
  }
  .d-btn-orange {
    width: 100%;
    height: 44px;
    background-color: #00bcff;
    border: none;
    color: #ffffff;
  }
}
.l-tishi {
  text-align: center;
  color: #999999;
  font-weight: 600;
  span {
    color: #00bcff;
  }
}
.registersuccess {
  padding: 50px 0;
  .gou_img {
    text-align: center;
    img {
      width: 80px;
      height: 80px;
    }
  }
  .congratulations {
    text-align: center;
    color: #111111;
    font-weight: 600;
  }
  .finish-text {
    text-align: center;
    color: #999999;
    margin-top: 10px;
    span {
      color: #00bcff;
    }
  }
  .succes-btn {
    text-align: center;
    margin: 40px 0 20px 0;
    button {
      background-color: #00bcff;
      height: 40px;
      width: 180px;
      color: #ffffff;
      border-radius: 0px;
    }
  }
}
</style>
