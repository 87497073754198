<template>
  <div class="">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="密码登陆" name="mima">
        <el-form ref="rmimaform" :model="rmimaform" class="from-box1">
          <el-form-item prop="phone">
            <el-input
              type="text"
              maxlength="11"
              placeholder="请输入手机号或邮箱"
            />
          </el-form-item>
          <el-form-item prop="code">
            <el-input type="password" placeholder="请输入登录密码"></el-input>
          </el-form-item>

          <el-form-item
            ><button class="d-btn-orange">登录</button></el-form-item
          >
        </el-form>
        <div class="forget font14">
          <span @click="forgetpassword()">忘记密码 </span>|
          <span @click="toregister()">免费注册</span>
        </div>
        <div class="agreement font14" style="color: #00bcff; margin-top: 10px">
          <el-checkbox v-model="checked"></el-checkbox>
          <span>
            已阅读并同意Freemen
            <a
              href="https://freemen.work/UserAgreement.html"
              target="_blank"
              style="text-decoration: none; color: #00bcff"
              >《用户协议》</a
            >和<a
              href="https://freemen.work/PrivacyPolicy.html"
              target="_blank"
              style="text-decoration: none; color: #00bcff"
              >《隐私政策》</a
            ></span
          >
        </div>
        <div class="l-tihsi font16">其他登录方式</div>
        <div class="l-img" @click="getWxcode">
          <img src="../../assets/images/login/yuanweixin.png" />
        </div>
      </el-tab-pane>
      <el-tab-pane label="短信登陆" name="duanxin">
        <el-form
          ref="loginForm"
          :model="rdxform"
          :rules="rules"
          class="from-box1"
        >
          <el-form-item prop="phone">
            <el-input
              type="text"
              maxlength="11"
              placeholder="手机号"
              v-model="rdxform.phone"
            />
          </el-form-item>
          <el-form-item prop="code">
            <div class="verify-wrapper" style="display: flex">
              <el-input
                type="text"
                maxlength="6"
                placeholder="验证码"
                v-model="rdxform.code"
              ></el-input>
              <el-button
                class="btn-orange"
                :disabled="disabled"
                @click="getCode"
                >{{ valiBtn }}</el-button
              >
            </div>
          </el-form-item>
          <el-form-item
            ><button class="d-btn-orange">登录</button></el-form-item
          >
        </el-form>
        <div class="forget font14">
          <span @click="forgetpassword()">忘记密码 </span>|
          <span @click="toregister()">免费注册</span>
        </div>
        <div class="agreement font14" style="color: #00bcff; margin-top: 10px">
          <el-checkbox v-model="checked"></el-checkbox>
          <span>
            已阅读并同意Freemen
            <a
              href="https://freemen.work/UserAgreement.html"
              target="_blank"
              style="text-decoration: none; color: #00bcff"
              >《用户协议》</a
            >和<a
              href="https://freemen.work/PrivacyPolicy.html"
              target="_blank"
              style="text-decoration: none; color: #00bcff"
              >《隐私政策》</a
            ></span
          >
        </div>
        <div class="l-tihsi font16">其他登录方式</div>
        <div class="l-img" @click="getWxcode">
          <img src="../../assets/images/login/yuanweixin.png" />
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import Bus from "../../common/bus.js";
export default {
  data() {
    var checkPhone = (rule, value, callback) => {
      const reg =
        /^0?(13[0-9]|15[012356789]|18[0-9]|14[578]|16[6]|17[035768]|19[19])[0-9]{8}$/;
      if (reg.test(value)) {
        return callback();
      }
      callback("请输入合法的手机号");
    };
    return {
      // recruittype:'',
      activeName: "mima",
      checked: true,
      rmimaform: {
        phone: "",
        minum: "",
      },
      rules: {
        phone: [{ validator: checkPhone, required: true, trigger: "blur" }],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
      rdxform: {
        phone: "",
        code: "",
      },
      valiBtn: "获取验证码",
      disabled: false,
      forgetshow: false,
    };
  },
  methods: {
    getWxcode() {
      if (!this.checked) {
        this.$message({
          message: "请先同意用户协议和隐私政策",
          type: "error",
        });
        return;
      }
      this.$wxPoput.show();
    },
    toregister() {
      this.$emit("register", "20");
    },
    forgetpassword() {
      // console.log("wangji");
      Bus.$emit("forgetpsdstuts", true);
    },
    handleClick(tab, event) {
      // console.log(tab, event);
    },
    //获取验证码 并只验证手机号 是否正确
    getCode() {
      this.$refs["loginForm"].validateField("phone", (err) => {
        if (err) {
          console.log("未通过");
          return;
        } else {
          console.log("已通过");
          this.tackBtn(); //验证码倒数60秒
          // let fd = new FormData(); //POST 请求需要 转化为Form
          // fd.append('PhoneNumber', this.form.phone);
          // fd.append('NeedCheck', 2);
          // this.$axios({
          // 	method: 'POST',
          // 	data: fd,
          // 	url: '/api/sgsaccount/vcodeget'
          // }).then(res => {
          // 	console.log(res);
          // });
        }
      });
    },
    tackBtn() {
      //验证码倒数60秒
      let time = 60;
      let timer = setInterval(() => {
        if (time == 0) {
          clearInterval(timer);
          this.valiBtn = "获取验证码";
          this.disabled = false;
        } else {
          this.disabled = true;
          this.valiBtn = time + "秒后重发";
          time--;
        }
      }, 1000);
    },
  },
};
</script>
<style>
.el-tabs__nav-wrap::after {
  background-color: #fff !important;
}
.el-tabs__nav-scroll {
  display: flex;
  align-items: center;
  justify-content: center;
}
.el-tabs__item.is-active {
  color: #303133;
}
</style>
<style lang="less" scoped>
.from-box1 {
  /deep/ .el-input__inner {
    border-radius: 0px;
  }
  /deep/ .el-button {
    border-radius: 0px;
  }
  /deep/ .el-checkbox__input.is-checked,
  .btn-orange {
    margin-left: 10px;
  }
  .d-btn-orange {
    width: 100%;
    height: 44px;
    background-color: #00bcff;
    border: none;
    color: #ffffff;
  }
}
.forget:hover {
  cursor: pointer;
}
.forget {
  color: #666666;
}
.l-tihsi {
  color: #111111;
  text-align: center;
  margin-top: 30px;
}
.l-img {
  text-align: center;
  margin-top: 20px;
  img:hover {
    cursor: pointer;
  }
  img {
    width: 64px;
    height: 64px;
  }
}
</style>
