<template>
  <div class="code-box">
    <div class="tit font22">手机APP扫码安全登录</div>
    <div class="shixiao" v-if="outTime">
      <div class="s-tihsi">二维码失效</div>
      <div class="s-btn" @click="getQrcode">
        <span>刷新二维码</span>
      </div>
    </div>
    <div class="code_img" v-else>
      <canvas id="canvas"></canvas>
      <!-- <img src="../../assets/images/login/code-pic.png" > -->
    </div>
    <div class="ts_text font14">
      <img src="../../assets/images/login/saocode.png" />
      打开FreemenAPP 扫一扫登录
    </div>
    <!-- <div class="other font16">其他登录方式</div>
    <div class="weixin">
      <img src="../../assets/images/login/yuanweixin.png" @click="getWxcode" />
    </div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      outTime: false,
      outTimer: null,
    };
  },
  created() {
    this.getQrcode();
  },
  destroyed() {
    clearInterval(this.outTimer);
  },
  methods: {
    getWxcode() {
      if (!this.checked) {
        this.$message({
          message: "请先同意用户协议和隐私政策",
          type: "error",
        });
        return;
      }
      this.$wxPoput.show();
    },
    loginSuccess(res) {
      window.sessionStorage.setItem("token", res.data.token);
      window.sessionStorage.setItem("userInfo", JSON.stringify(res.data));
      this.$IM.login({
        id: res.data.id,
        error: (err) => {
          let that = this;
          if (err.data.type == 17) {
            that.$IM.register({
              id: res.data.id,
              success: () => {
                that.$IM.login({
                  id: res.data.id,
                  success: () => {
                    window.IMlogin = true;
                  },
                });
              },
            });
          }
        },
        success: () => {
          window.IMlogin = true;
        },
      });

      this.$message({
        message: "登录成功",
        type: "success",
      });

      this.$router.push(res.data.email == null ? "/messagewrite" : "/index");
    },
    getQrcode() {
      this.outTime = false;

      this.$api.getQrcode("get").then((res) => {
        // console.log("scan, #####  $api.getQrcode.res = ", res);
        // this.$message({
        //   message: "二维码获取成功",
        //   type: "success",
        // });
        let timer = setTimeout(() => {
          var QRCode = require("qrcode");
          var canvas = document.getElementById("canvas");
          QRCode.toCanvas(canvas, res.data, (error) => {
            if (error) console.error(error);
            clearTimeout(timer);
          });
        }, 20);
        let time = 0;
        let uuid = res.data.replaceAll("freemen.work:login:code:", "");
        this.outTimer = setInterval(() => {
          time++;
          // console.log("scan code to login, setInterval----------- time count:", time);
          this.$api.getToken("get",
              { uuid: uuid,})
            .then((r) => {
              // console.log("scan ####  getToken.res = ", r);
              if (r.code === 10200  && r.data !== uuid) { // r.data.indexOf("user_") !== -1
                this.$message({
                  message: "登录成功",
                  type: "success",
                });
                window.sessionStorage.setItem(
                  "token",
                  r.data.replace(/user_/g, "")
                );
                this.$api.getDetail("get").then((rr) => {
                  // console.log("scan ####  getDetail.res = ", rr);
                  this.loginSuccess(rr);
                });
                // console.log("scan over ####  to clear Interval ------  ");
                clearInterval(this.outTimer);
              }
            });
          if (time >= 300) {
            this.outTime = true;
            clearInterval(this.outTimer);
          }
        }, 1500);
      });
    },
  },
};
</script>

<style lang="less" scoped>
.code-box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  line-height: 3;
  .tit {
    text-align: center;
    color: #111111;
    font-weight: 600;
  }
  .code_img {
    text-align: center;
    img {
      width: 220px;
      height: 220px;
      // border: 1px solid #000000;
    }
  }
  .shixiao {
    width: 220px;
    height: 200px;
    background-color: #f3f3f3;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    line-height: 4;
    margin-bottom: 20px;
    .s-tihsi {
      color: #999999;
      font-size: 14px;
    }
    .s-btn:hover {
      cursor: pointer;
    }
    .s-btn {
      span {
        color: #ffffff;
        font-size: 14px;
        padding: 13px 20px;
        background-color: #00bcff;
        border-radius: 4px;
      }
    }
  }
  .ts_text {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 26px;
      height: 24px;
      margin-right: 5px;
    }
  }
  .other {
    color: #111111;
    margin-top: 20px;
  }
  .weixin {
    text-align: center;
    margin-top: 20px;
    img:hover {
      cursor: pointer;
    }
    img {
      width: 63px;
      height: 63px;
    }
  }
}
</style>
