<template>
  <div class="">
    <div class="tit font14">首次验证登录即注册</div>
    <el-form ref="form" :model="form" :rules="rules" class="from-box1">
      <el-form-item prop="phone">
        <el-input
          type="text"
          maxlength="11"
          placeholder="手机号"
          v-model="form.phone"
        />
      </el-form-item>
      <el-form-item prop="code">
        <div class="verify-wrapper" style="display: flex">
          <el-input
            type="text"
            maxlength="6"
            placeholder="验证码"
            v-model="form.code"
          ></el-input>
          <el-button class="btn-orange" :disabled="disabled" @click="getCode">{{
            valiBtn
          }}</el-button>
        </div>
      </el-form-item>
      <el-form-item>
        <!-- <el-checkbox v-model="xieyichecked"
          >已阅读同意<span style="color: #409eff"
            >《Freeman用户服务协议》</span
          ></el-checkbox
        > -->
        <div class="agreement font14" style="color: #00bcff; margin-top: 10px">
          <el-checkbox v-model="xieyichecked"></el-checkbox>
          <span>
            已阅读并同意Freemen
            <a
              href="https://freemen.work/UserAgreement.html"
              target="_blank"
              style="text-decoration: none; color: #00bcff"
              >《用户协议》</a
            >和<a
              href="https://freemen.work/PrivacyPolicy.html"
              target="_blank"
              style="text-decoration: none; color: #00bcff"
              >《隐私政策》</a
            ></span
          >
        </div>
      </el-form-item>
      <el-form-item
        ><button class="d-btn-orange" @click.prevent="towrite('form')">
          立即注册
        </button></el-form-item
      >
    </el-form>
    <div class="l-tishi font14" @click="fastlogin()">
      已有账号？<span>快速登录>></span>
    </div>
    <!-- @click.prevent="onSubmit('form')" -->
  </div>
</template>

<script>
export default {
  data() {
    var checkPhone = (rule, value, callback) => {
      const reg =
        /^0?(13[0-9]|15[012356789]|18[0-9]|14[578]|16[6]|17[035768]|19[19])[0-9]{8}$/;
      if (reg.test(value)) {
        return callback();
      }
      callback("请输入合法的手机号");
    };
    return {
      // 测试
      loginForm: {
        username: "admin",
        password: "123456",
      },

      // 正式
      valiBtn: "获取验证码",
      disabled: false,
      xieyichecked: false, //协议选择
      autoLogin: false,
      form: {
        phone: "",
        code: "",
      },
      rules: {
        phone: [{ validator: checkPhone, required: true, trigger: "blur" }],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
    };
  },
  methods: {
    fastlogin() {
      this.$emit("changestuts", 2);
    },
    towrite(name) {
      if (this.xieyichecked) {
        this.$refs[name].validate((valid) => {
          if (valid) {
            let rejisterConfig = {
              phone: this.form.phone,
              code: this.form.code,
            };
            this.$api.codeLogin("post", rejisterConfig).then((res) => {
              if (res.code !== 10200) {
                this.$message({
                  message: res.message,
                  type: "error",
                });
                return;
              }
              window.sessionStorage.setItem("token", res.data.token);
              window.sessionStorage.setItem("userInfo", JSON.stringify(res.data));
              this.$IM.login({
                id: res.data.id,
                error: (err) => {
                  let that = this;
                  if (err.data.type == 17) {
                    that.$IM.register({
                      id: res.data.id,
                      success: () => {
                        that.$IM.login({id: res.data.id});
                      },
                    });
                  }
                },
              });
              this.$message({
                message: "登录成功",
                type: "success",
              });
              this.$router.push("/messagewrite");
            });
          } else {
            return false;
          }
        });
      } else {
        this.$message({
          message: "请先同意用户协议",
          type: "error",
        });
      }
    },
    //获取验证码 并只验证手机号 是否正确
    getCode() {
      this.$refs["form"].validateField("phone", (err) => {
        if (err) {
          return;
        } else {
          let fd = {
            phone: this.form.phone,
            type: 2,
          };
          this.$api.phonemsg("post", fd).then(() => {
            this.tackBtn();
          });
        }
      });
    },
    tackBtn() {
      //验证码倒数60秒
      let time = 60;
      let timer = setInterval(() => {
        if (time == 0) {
          clearInterval(timer);
          this.valiBtn = "获取验证码";
          this.disabled = false;
        } else {
          this.disabled = true;
          this.valiBtn = time + "秒后重发";
          time--;
        }
      }, 1000);
    },
    onSubmit(formName) {
      //点击登录 验证手机& 验证码是否符合条件
      this.$refs[formName].validate((valid) => {
        // 为表单绑定验证功能
        if (valid) {
          console.log("正确登陆");
        } else {
          this.dialogVisible = true;
          return false;
        }
      });
      this.$http.post("login", this.loginForm).then((res) => {
        // console.log(res)
        if (res.data.meta.status == 200) {
          this.$message({
            message: "登录成功",
            type: "success",
          });
          window.sessionStorage.setItem("token", res.data.data.token);
          this.$router.push("/index");
        } else {
          window.sessionStorage.setItem("token", "hhjbsajdbkjasbdjkfbasjk");
          this.$router.push("/index");
          this.$message({
            message: res.data.meta.msg,
            type: "error",
          });
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
.interview {
  margin-top: 30px;
  .tit {
    text-align: center;
    line-height: 3;
    color: #999999;
    font-weight: 600;
  }
  .l-tishi:hover {
    cursor: pointer;
  }
  .l-tishi {
    text-align: center;
    color: #999999;
    font-weight: 600;
    span {
      color: #00bcff;
    }
  }
}
.from-box1 {
  /deep/ .el-input__inner {
    border-radius: 0px;
  }
  /deep/ .el-button {
    border-radius: 0px;
  }

  .btn-orange {
    margin-left: 10px;
  }
  .d-btn-orange {
    width: 100%;
    height: 44px;
    background-color: #00bcff;
    border: none;
    color: #ffffff;
  }
}
</style>
