<template>
  <div class="">
    <el-dialog
      :visible.sync="dialogVisible"
      width="550px"
      :show-close="false"
      :modal-append-to-body="false"
      :lock-scroll="false"
    >
      <div class="find">
        <span class="closeicon" @click="closebox()">
          <i class="el-icon-error"></i>
        </span>
        <div class="tab-list font16">
          <div :class="boxnum == 1 ? 'tl-active' : ''" @click="changebox(1)">
            手机找回
          </div>
          <div :class="boxnum == 2 ? 'tl-active' : ''" @click="changebox(2)">
            邮箱找回
          </div>
        </div>
        <!-- 手机找回 -->
        <div class="shouji" v-if="boxnum == 1">
          <el-form
            ref="loginForm"
            :model="sjregisterform"
            :rules="sjrules"
            class="from-box1"
          >
            <el-form-item prop="phone"
              ><el-input
                type="text"
                maxlength="11"
                placeholder="请输入手机号码"
                v-model="sjregisterform.phone"
            /></el-form-item>
            <el-form-item prop="code">
              <div class="verify-wrapper" style="display: flex">
                <el-input
                  type="text"
                  maxlength="6"
                  placeholder="请输入短信验证码"
                  v-model="sjregisterform.code"
                ></el-input>
                <el-button
                  class="btn-orange"
                  :disabled="sjdisabled"
                  @click="getCodephone"
                  >{{ sjvaliBtn }}</el-button
                >
              </div>
            </el-form-item>
            <el-form-item prop="phone"
              ><el-input
                type="text"
                maxlength="20"
                placeholder="请设置6至20位登录密码"
                v-model="sjregisterform.password1"
            /></el-form-item>
            <el-form-item prop="phone"
              ><el-input
                type="text"
                maxlength="20"
                placeholder="请确认登录密码"
                v-model="sjregisterform.password2"
            /></el-form-item>
            <el-form-item
              ><button class="d-btn-orange">确定</button></el-form-item
            >
            <div class="zhuyi font14">
              温馨提示：如果你无法正常获取手机验证码，可拨打
              0571-85109006联系客服帮忙找回！
            </div>
          </el-form>
        </div>
        <!-- 邮箱找回 -->
        <div class="email" v-if="boxnum == 2">
          <el-form
            ref="loginForm"
            :model="emailregisterform"
            :rules="emailrules"
            class="from-box1"
          >
            <el-form-item prop="phone"
              ><el-input
                type="text"
                placeholder="请输入邮箱"
                v-model="emailregisterform.email"
            /></el-form-item>
            <el-form-item prop="code">
              <div class="verify-wrapper" style="display: flex">
                <el-input
                  type="text"
                  maxlength="6"
                  placeholder="请输入短信验证码"
                  v-model="emailregisterform.code"
                ></el-input>
                <el-button
                  class="btn-orange"
                  :disabled="emaildisabled"
                  @click="getCodeemail"
                  >{{ emailvaliBtn }}</el-button
                >
              </div>
            </el-form-item>
            <el-form-item prop="phone"
              ><el-input
                type="text"
                maxlength="20"
                placeholder="请设置6至20位登录密码"
                v-model="emailregisterform.password1"
            /></el-form-item>
            <el-form-item prop="phone"
              ><el-input
                type="text"
                maxlength="20"
                placeholder="请确认登录密码"
                v-model="emailregisterform.password2"
            /></el-form-item>
            <el-form-item
              ><button class="d-btn-orange">确定</button></el-form-item
            >
            <div class="zhuyi font14">
              温馨提示：如果你无法正常获取手机验证码，可拨打
              0571-85109006联系客服帮忙找回！
            </div>
          </el-form>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Bus from "../../common/bus.js";
export default {
  data() {
    var checkPhone = (rule, value, callback) => {
      const reg =
        /^0?(13[0-9]|15[012356789]|18[0-9]|14[578]|16[6]|17[035768]|19[19])[0-9]{8}$/;
      if (reg.test(value)) {
        return callback();
      }
      callback("请输入合法的手机号");
    };
    return {
      dialogVisible: false,
      boxnum: 1,
      // 手机
      sjregisterform: {
        phone: "",
        code: "",
        password1: "",
        password2: "",
      },
      sjrules: {
        phone: [{ validator: checkPhone, required: true, trigger: "blur" }],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
      sjvaliBtn: "获取验证码",
      sjdisabled: false,
      // 邮箱
      emailregisterform: {
        email: "",
        minum: "",
        password1: "",
        password2: "",
      },
      emailrules: {
        phone: [{ validator: checkPhone, required: true, trigger: "blur" }],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
      emailvaliBtn: "获取验证码",
      emaildisabled: false,
    };
  },
  mounted() {
    Bus.$on("forgetpsdstuts", (data) => {
      // console.log("接受到", data);
      // this.dialogVisible = data;
    });
  },
  methods: {
    changebox(numb) {
      this.boxnum = numb;
    },
    closebox() {
      this.dialogVisible = false;
    },
    //手机获取验证码
    getCodephone() {
      this.$refs["loginForm"].validateField("phone", (err) => {
        if (err) {
          // console.log("未通过");
          return;
        } else {
          // console.log("已通过");
          this.tackBtnPhone(); //验证码倒数60秒
          let fd = new FormData(); //POST 请求需要 转化为Form
          fd.append("PhoneNumber", this.form.phone);
          fd.append("NeedCheck", 2);
          // this.$axios({
          // 	method: 'POST',
          // 	data: fd,
          // 	url: '/api/sgsaccount/vcodeget'
          // }).then(res => {
          // 	console.log(res);
          // });
        }
      });
    },
    tackBtnPhone() {
      //验证码倒数60秒
      let time = 60;
      let timer = setInterval(() => {
        if (time == 0) {
          clearInterval(timer);
          this.sjvaliBtn = "获取验证码";
          this.sjdisabled = false;
        } else {
          this.sjdisabled = true;
          this.sjvaliBtn = time + "秒后重发";
          time--;
        }
      }, 1000);
    },
    //邮箱获取验证码
    getCodeemail() {
      this.$refs["loginForm"].validateField("phone", (err) => {
        if (err) {
          console.log("未通过");
          return;
        } else {
          console.log("已通过");
          this.tackBtnEmail(); //验证码倒数60秒
          let fd = new FormData(); //POST 请求需要 转化为Form
          fd.append("PhoneNumber", this.form.phone);
          fd.append("NeedCheck", 2);
          // this.$axios({
          // 	method: 'POST',
          // 	data: fd,
          // 	url: '/api/sgsaccount/vcodeget'
          // }).then(res => {
          // 	console.log(res);
          // });
        }
      });
    },
    tackBtnEmail() {
      //验证码倒数60秒
      let time = 60;
      let timer = setInterval(() => {
        if (time == 0) {
          clearInterval(timer);
          this.emailvaliBtn = "获取验证码";
          this.emaildisabled = false;
        } else {
          this.emaildisabled = true;
          this.emailvaliBtn = time + "秒后重发";
          time--;
        }
      }, 1000);
    },
  },
};
</script>
<style type="text/css">
.el-dialog__header,
.el-dialog__body {
  padding: 0 !important;
}
</style>
<style lang="less" scoped>
.tab-list {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f3f3f3;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    padding: 10px 0;
    color: #111111;
  }
  .tl-active {
    background-color: #ffffff;
    color: #666666;
  }
}
.find {
  position: relative;
}
.closeicon {
  position: absolute;
  top: -40px;
  right: 0;
  font-size: 26px;
  color: #f3f3f3;
}
.shouji,
.email {
  height: 100%;
  // padding: 0 50px;
  padding: 50px 50px 20px 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.from-box1 {
  /deep/ .el-input__inner {
    border-radius: 0px;
  }
  /deep/ .el-button {
    border-radius: 0px;
  }

  .btn-orange {
    margin-left: 10px;
  }
  .d-btn-orange {
    width: 100%;
    height: 44px;
    background-color: #00bcff;
    border: none;
    color: #ffffff;
  }
}
.zhuyi {
  color: #666666;
}
</style>
